import { useStore } from '@nanostores/preact'
import { isCartOpen, cartItems, removeCartItem, updateCartItemQuantity } from './cartStore'

export default function CartFlyout(){
    const $isCartOpen = useStore(isCartOpen);
    const $cartItems = useStore(cartItems);

    function removeToCart(cartItem){
        // Se invoca la función para eliminar item del carrito
        removeCartItem(cartItem);
        // Para refrescar el estado del carrito y retornarlo
        isCartOpen.set(false);
        isCartOpen.set(true);
    }

    function updateToCartQuantity(cartItem){
        updateCartItemQuantity(cartItem);
    }

    return $isCartOpen ? 
    <aside class="h-screen fixed overflow-y-auto w-32 z-40 bg-white top-0 mt-14 max-[1070px]:mt-12 max-[580px]:mt-11 max-[322px]:mt-12 max-[300px]:mt-11 right-0 border">
        {
            Object.values($cartItems).length ? (
                <ul>
                    <nav>
                        <ul>
                            <a href="/contacto/cotizar" title="Cotizar herramientas">
                                <li class="bg-red-600 hover:bg-red-500 text-white text-sm font-medium px-2 py-1 w-full text-center">Cotizar herramientas</li>
                            </a>
                            <a href="/contacto/demo" title="Demostración de herramientas">
                                <li class="bg-slate-600 hover:bg-slate-500 text-white text-sm font-medium px-2 py-1 w-full text-center">Demostración de herramientas</li>
                            </a>
                        </ul>
                    </nav>
                    {
                        Object.values($cartItems).map(cartItem => (
                            <li>
                                <img class="w-28 h-28" src={cartItem.imageSrc} alt={cartItem.name} />
                                <h3 class="text-xs font-medium">Modelo: {cartItem.name}</h3>
                                <h4 class="text-xs font-medium">Ref: {cartItem.id}</h4>
                                <p class="text-sm">Cantidad: <input type="number" name="" id={`itemQuantity${cartItem.id}`} value={cartItem.quantity} min={1} class="w-11" onChange={ () => { updateToCartQuantity(cartItem) }} /></p>
                                <button class="bg-red-800 hover:bg-red-700 text-white py-1 font-semibold w-full" onClick={ () => { removeToCart(cartItem)} }>Eliminar</button>
                            </li>
                        ))
                    }
                </ul>
            ) : 
            <div class="space-y-2">
                <p class="text-center text-balance text-base font-medium bg-slate-600 text-white py-2">¡El carrito está vacío!</p>
                <a href="/herramientas" title="Herramientas industriales">
                    <div class="bg-[#023D75] hover:bg-[#0057a8] text-white text-sm font-medium px-2 py-1 w-full text-center">Encuentra tus herramientas</div>
                </a>
            </div>
        }
    </aside> 
    : 
    null;
}